<template>
  <div class="button" :class="'button--' + color">
    <slot />
    <div class="button__overlay" v-if="disable">
      <span class="button__overlay__text">{{ disabledMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    disable: Boolean,
    disabledMessage: String,
    color: {
      type: String,
      default: 'primary',
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.button {
  position: relative;
  border: 2px solid;
  $shadow: 16px;
  box-shadow: 0 #{$shadow} #{$shadow} #{$shadow * -1} $shadow-primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 99999px;

  & + & {
    margin-top: $spacing-normal;
  }

  &--primary {
    border-color: $border-primary-color;
    background-color: $background-primary;
    color: $text-primary;
  }
  &--green {
    border-color: $border-green-color;
    background-color: $background-green;
    color: $text-green;
  }
  &--red {
    border-color: $border-red-color;
    background-color: $background-red;
    color: $text-red;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(white, .6);
    border-radius: 99999px;

    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
      font-weight: bold;
    }
  }

  &__placeholder:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(white, .6);
    border-radius: 99999px;
  }
}
</style>
