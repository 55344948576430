<template>
  <div class="shop">
    <title-component>LionCoins einlösen</title-component>
    <points-counter />
    <div class="shop__closed-notice" v-if="isShopClosed">
      <p>Shop ist geschlossen :)</p>
    </div>
    <div class="shop__products" v-else>
      <button-component
        class="shop__products__product"
        :class="{
        'shop__products__product--disabled-cost': !isAvailable(product) && getPurchaseCountdownSeconds(product) <= 0,
        'shop__products__product--disabled-timeout': !isAvailable(product) && getPurchaseCountdownSeconds(product) > 0,
        }"
        @click.native="buyProduct(product)"
        :disable="!isAvailable(product)"
        :disabled-message="getPurchaseCountdownSeconds(product) > 0 ? `In ${ $options.filters.readableCountdown(getPurchaseCountdownSeconds(product)) } verfügbar` : ''"
        v-for="product in $store.state.config.products"
        :key="'p' + product.id"
      >
        <strong class="shop__products__product__title">
          {{ product.name }}
        </strong>
        <span class="shop__products__product__duration">
          {{ duration(product.duration_seconds) }}
        </span>
        <span
            class="shop__products__product__price"
            :class="'shop__products__product__price--' + (product.price < 0 ? 'receive' : 'pay')"
        >
          {{ product.price }}
        </span>
        <lion-coin class="shop__products__product__coin" />
      </button-component>
    </div>
  </div>
</template>

<script>
import eventDatesParser from "@/util/eventdates-parser";
import {now, unix} from "@/util/date-helper";
import TitleComponent from "@/components/title";
import LionCoin from "@/components/lion-coin";
import PointsCounter from "@/components/points-counter";
import ButtonComponent from "@/components/button";

export default {
  name: "ShopView",
  components: {ButtonComponent, PointsCounter, LionCoin, TitleComponent},
  data() {
    return {
      unixNow: unix(),
      unixInterval: null,
    }
  },
  computed: {
    isShopClosed() {
      return false;
      return eventDatesParser(
        this.$store.getters.closedDates,
        now()
      );
    },
  },
  methods: {
    isAvailable(product) {
      if (this.getPurchaseCountdownSeconds(product) > 0) return false;
      return product.price <= this.$store.state.credits;
    },
    getPurchaseCountdownSeconds(product) {
      //if (product.lastPurchased === false) return 0;
      //
      //const unixNow = new Date().getTime() / 1000;
      //const unixPurchased = new Date(product.lastPurchased).getTime() / 1000;
      //
      //const unixCanPurchase = unixPurchased + product.purchaseTimeoutSeconds;
      //
      //return unixCanPurchase - unixNow;

      //return this.$store.getters.productAvailableInSeconds(product.id, this.$store.getters.settings.product_buy_timeout);
      return this.$store.getters.productAvailableAtUnix(product.id, this.$store.state.config.product_buy_timeout) - this.unixNow;
    },
    buyProduct(product) {
      if (!this.isAvailable(product)) return;

      const msg = `Willst du "${product.name}" für ${product.price} Credits kaufen?`;
      const confirm = window.confirm(msg);

      if (confirm) {
        this.$store.commit('buyProduct', product);
        this.$router.push(`/timer?product=${product.id}`);
      }
    },
    duration(seconds) {
      let mins = Math.floor(seconds / 60);
      let secs = seconds % 60;
      return `${mins}m${secs > 0 ? ` ${secs}s`: ''}`
    },
  },
  mounted() {
    this.unixInterval = setInterval(() => {
      this.unixNow = unix()
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.unixInterval);
  },
};
</script>

<style lang="scss">
.shop {
  &__products {
    &__product {

      &__title {
      }
      &__duration {
        margin-left: .5em;
      }
      &__price {
        margin-left: auto;
        font-weight: bold;
        margin-right: .5em;

        &--receive {
          color: $green;
        }
      }
      &__coin {
      }

      &--disabled {
        &-cost {

          .shop__products__product__price {
            color: red;
          }
        }

        &-timeout {
        }
      }
    }
  }
}
</style>
