<template>
  <div class="tags-icon">
    <img :src="require('@/assets/tags.svg')" alt="Tags Icon" class="tags-icon__image">
  </div>
</template>

<script>

export default {
  name: "TagsIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tags-icon {
  display: inline-block;
  height: 1em;
  width: 1em;

  &__image {
    height: 100%;
    width: 100%;
  }
}
</style>
