<template>
  <header class="header">
    <div class="header__close" v-show="canClose" @click="$router.go(-1)">X</div>
    <div class="header__logo" @click="clickLogo">
      <img :src="require('@/assets/logo-lion.png')" alt="Loin Logo" class="header__logo__image">
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      logoClicked: 0,
    };
  },
  props: {
    canClose: Boolean,
  },
  methods: {
    clickLogo() {
      this.logoClicked++;

      if (this.logoClicked >= 10) {
        this.logoClicked = 0;
        this.$router.push("/admin");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  display: flex;
  justify-content: center;
  position: relative;

  padding: $spacing-small $spacing-xlarge;
  background-color: $background-primary;

  box-shadow: 0 0 $spacing-normal 0 $shadow-primary-color;

  &__close {
    position: absolute;
    left: $spacing-xlarge;
  }

  &__logo {
    &__image {
      height: $spacing-xlarge;
    }
  }
}
</style>
