<template>
  <div class="points">
    <title-component has-rich-content>
      <span>Deine LionCoins</span>
      <router-link to="/coupons"><TagsIcon /></router-link>
    </title-component>
    <points-counter />
    <p class="points__status" v-if="hasCollectedTodaysCredits">
      Du erhälst in {{ secondsToMidnight() | readableCountdown }} neue LionCoins
    </p>
    <div class="points__collector" v-else>
      <button-component class="points__collector__button" @click.native="collectCredits">
        <strong>Jetzt&nbsp;<del v-if="hasSpecialRate">{{ normalRate }}</del>&nbsp;{{ todaysRate }}&nbsp;</strong><lion-coin /><strong>&nbsp;einsammeln</strong>
      </button-component>
      <p class="points__collector__message" v-if="hasSpecialRate">Heute: Double Time!</p>
    </div>
    <div class="points__log">
      <title-component>Transaktionen</title-component>
      <ul class="points__log__list">
        <li class="points__log__list__item" v-for="(transaction, i) in transactions" :key="'transaction' + i">
          <span class="points__log__list__item__label">{{ transaction.title }}</span>
          <span class="points__log__list__item__date">{{ transaction.date | unixToDateString }}</span>
          <span
            class="points__log__list__item__number"
            :class="'points__log__list__item__number--' + (transaction.value < 0 ? 'negative' : 'positive')"
          >
            {{ transactionDisplayValue(transaction.value) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import eventDatesParser from "@/util/eventdates-parser";
import {dateString, now, unix, unixToDateString} from "@/util/date-helper";
import TitleComponent from "@/components/title";
import PointsCounter from "@/components/points-counter";
import ButtonComponent from "@/components/button";
import LionCoin from "@/components/lion-coin";
import TagsIcon from "@/components/tags";

export default {
  name: "PointsView",
  components: {TagsIcon, LionCoin, ButtonComponent, PointsCounter, TitleComponent},
  data() {
    return {
      unixNow: unix(),
      unixInterval: null,
    }
  },
  computed: {
    transactions() {
      return this.$store.state.logs.transactions.filter(transaction => transaction.value !== 0)
    },
    hasSpecialRate() {
      return eventDatesParser(
          this.$store.getters.specialDates,
          now()
      );
    },
    todaysRate() {
      return this.hasSpecialRate ? this.specialRate : this.normalRate;
    },
    normalRate() {
      return this.$store.state.config.credits_per_day;
    },
    specialRate() {
      return this.normalRate * this.$store.state.config.credit_multiplier;
    },
    hasCollectedTodaysCredits() {
      if (!this.$store.state.logs.last_collection) return false;
      return unixToDateString(this.$store.state.logs.last_collection) === dateString()
    },
  },
  methods: {
    secondsToMidnight() {
      const midnight = new Date(now());
      midnight.setHours(23,59,59,0);

      return unix(midnight) - this.unixNow;
    },
    transactionDisplayValue(value) {
      const absValue = Math.abs(value);
      let stringValue = absValue.toString();

      if (absValue < 10) stringValue = " " + stringValue;

      return (value < 0 ? "-" : "+") + stringValue;
    },
    collectCredits() {
      this.$store.commit('collectCredits', this.todaysRate);
    }
  },
  mounted() {
    this.unixInterval = setInterval(() => {
      this.unixNow = unix()
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.unixInterval);
  },
};
</script>

<style lang="scss">
  .points__status {
    text-align: center;
    font-size: $font-normal;
    color: $text-grey;
  }

  .points__log {
    margin-top: $spacing-xlarge;

    &__list {
      list-style: none;
      padding: 0;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: $spacing-normal $spacing-large;

        &:nth-of-type(2n) {
          background-color: $background-primary;
          border-radius: 99999px;
        }

        &__label {
          font-weight: bold;
          margin-right: 1em;
        }

        &__date {
          color: $text-grey;
        }

        &__number {
          margin-left: auto;
          font-family: monospace;

          &--positive { color: $green }
          &--negative { color: $red }
        }
      }
    }
  }
</style>
