<template>
  <h1 class="title" :class="hasRichContent ? 'title--has-rich-content' : ''">
    <slot />
  </h1>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    hasRichContent: Boolean,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  color: $text-primary;
  font-size: $font-large;
  font-weight: bold;
  line-height: 1;
  padding: 0 0 $spacing-large;
  margin: 0 0 $spacing-large;
  border-bottom: 1px solid silver;

  &--has-rich-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
