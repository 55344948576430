import Vue from "vue";
import VueRouter from "vue-router";
import InstallView from "../views/Install.vue";
import PointsView from "../views/Points.vue";
import ShopView from "../views/Shop.vue";
import CouponsView from "../views/Coupons.vue";
import TimerView from "../views/Timer.vue";
import AdminView from "../views/Admin.vue";

Vue.use(VueRouter);

const checkInstall = () => {
  const installRoute = "/install";
  const defaultRoute = "/points";
  //const isIos = () => window.navigator.userAgent.indexOf('iPhone') !== -1;
  const isStandalone = () => window.navigator.standalone;

  if (!isStandalone) {
    return installRoute;
  }

  return defaultRoute;
};

const routes = [
  {
    path: "/",
    redirect: () => checkInstall(),
  },
  {
    path: "/points",
    name: "points",
    component: PointsView,
  },
  {
    path: "/shop",
    name: "shop",
    component: ShopView,
  },
  {
    path: "/coupons",
    name: "coupons",
    component: CouponsView,
  },
  {
    path: "/timer",
    name: "timer",
    component: TimerView,
    props: route => ({ productId: parseInt(route.query.product) || 0 })
  },
  {
    path: "/install",
    name: "install",
    component: InstallView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
