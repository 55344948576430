<template>
  <div class="admin">
    <title-component has-rich-content>
      Admin
      <small style="font-weight: lighter">App Version: {{appVersion}}</small>
    </title-component>
    <div class="admin__setting">
      <label for="setting_credits" class="admin__setting__label">Credits</label>
      <input type="number" class="admin__setting__input" id="setting_credits" v-model="credits" :disabled="hasSaved === 'credits'">
      <button class="admin__setting__btn-save" @click="saveCredits()">{{ hasSaved === 'credits' ? 'Gespeichert!' : 'Speichern' }}</button>
    </div>
    <div class="admin__setting">
      <label for="setting_clearlogs" class="admin__setting__label">Logs leeren</label>
      <select class="admin__setting__select" id="setting_clearlogs" v-model="logType" :disabled="hasSaved === 'logs'">
        <option disabled>Log wählen</option>
        <option :value="id" v-for="id in Object.keys(logTypes)" :key="id">{{ logTypes[id].name }}</option>
      </select>
      <button class="admin__setting__btn-save" @click="clearLogs()">{{ hasSaved === 'logs' ? 'Geleert!' : 'Leeren' }}</button>
    </div>
    <hr>
    <div class="admin__setting">
      <label for="setting_config_productBuyTimeout" class="admin__setting__label">product_buy_timeout</label>
      <input type="number" class="admin__setting__input" id="setting_config_productBuyTimeout" v-model="product_buy_timeout"  :disabled="hasSaved === 'product_buy_timeout'">
      <button class="admin__setting__btn-save" @click="saveProductBuyTimeout()">{{ hasSaved === 'product_buy_timeout' ? 'Gespeichert!' : 'Speichern' }}</button>
    </div>
    <div class="admin__setting">
      <label for="setting_config_creditsPerDay" class="admin__setting__label">credits_per_day</label>
      <input type="number" class="admin__setting__input" id="setting_config_creditsPerDay" v-model="credits_per_day"  :disabled="hasSaved === 'credits_per_day'">
      <button class="admin__setting__btn-save" @click="saveCreditsPerDay()">{{ hasSaved === 'credits_per_day' ? 'Gespeichert!' : 'Speichern' }}</button>
    </div>
    <div class="admin__setting">
      <label for="setting_config_creditsMultiplier" class="admin__setting__label">credit_multiplier</label>
      <input type="number" class="admin__setting__input" id="setting_config_creditsMultiplier" v-model="credit_multiplier"  :disabled="hasSaved === 'credit_multiplier'">
      <button class="admin__setting__btn-save" @click="saveCreditsMultiplier()">{{ hasSaved === 'credit_multiplier' ? 'Gespeichert!' : 'Speichern' }}</button>
    </div>
    <hr>
    <div class="admin__settings-group">
      <strong class="admin__settings-group__label">Produkte</strong>
      <div class="admin__settings-group__item" v-for="product in this.$store.state.config.products" :key="'p' + product.id">
        <div class="admin__settings-group__item__label">
          {{ product.name }} – {{ duration(product.duration_seconds) }}
        </div>
        <button class="admin__settings-group__item__action admin__settings-group__item__action--edit" @click="editProduct(product.id)">Bearbeiten</button>
        <button class="admin__settings-group__item__action admin__settings-group__item__action--delete" @click="deleteProduct(product.id)">Löschen</button>
      </div>
      <button class="admin__settings-group__add-action" @click="editProduct()">Neu hinzufügen</button>
    </div>
    <div class="admin__settings-group">
      <strong class="admin__settings-group__label">Event Daten</strong>
      <div class="admin__settings-group__item" v-for="event_date in this.$store.state.config.event_dates" :key="'e' + event_date.id">
        <div class="admin__settings-group__item__label">
          {{ event_date.type }} – {{ event_date.filter.type }} – {{ event_date.filter.value }}
        </div>
        <button class="admin__settings-group__item__action admin__settings-group__item__action--edit" @click="editEventDate(event_date.id)">Bearbeiten</button>
        <button class="admin__settings-group__item__action admin__settings-group__item__action--delete" @click="deleteEventDate(event_date.id)">Löschen</button>
      </div>
      <button class="admin__settings-group__add-action" @click="editEventDate()">Neu hinzufügen</button>
    </div>
    <div class="admin__settings-group">
      <strong class="admin__settings-group__label">Coupon Codes</strong>
      <div class="admin__settings-group__item" v-for="coupon_code in this.$store.state.config.coupon_codes" :key="'c' + coupon_code.code">
        <div class="admin__settings-group__item__label">
          {{ coupon_code.code }} – {{ coupon_code.value }}
        </div>
        <button class="admin__settings-group__item__action admin__settings-group__item__action--delete" @click="deleteCouponCode(coupon_code.code)">Löschen</button>
      </div>
      <button class="admin__settings-group__add-action" @click="addCouponCode()">Neu hinzufügen</button>
    </div>

    <div class="modal modal--product" v-if="product">
      <strong class="modal__title">Produkt</strong>
      <div class="modal__field">
        <label for="product_name" class="modal__field__label">Name</label>
        <input type="text" class="modal__field__input" id="product_name" v-model="product.name">
      </div>
      <div class="modal__field">
        <label for="product_duration" class="modal__field__label">Dauer (s)</label>
        <input type="number" class="modal__field__input" id="product_duration" v-model="product.duration_seconds">
      </div>
      <div class="modal__field">
        <label for="product_price" class="modal__field__label">Preis</label>
        <input type="number" class="modal__field__input" id="product_price" v-model="product.price">
      </div>
      <button class="modal__action" @click="saveProduct()">Speichern</button>
      <button class="modal__action" @click="product = false">Abbrechen</button>
    </div>
    <div class="modal modal--eventDate" v-if="event_date">
      <strong class="modal__title">Event Date</strong>
      <div class="modal__field">
        <label for="eventDate_type" class="modal__field__label">Typ</label>
        <select class="modal__field__select" id="eventDate_type" v-model="event_date.type">
          <option :value="types.date[key]" v-for="key in Object.keys(types.date)" :key="key">{{ types.date[key] }}</option>
        </select>
      </div>
      <div class="modal__field">
        <label for="eventDate_filterType" class="modal__field__label">Filter Typ</label>
        <select class="modal__field__select" id="eventDate_filterType" v-model="event_date.filter.type">
          <option :value="types.filter[key]" v-for="key in Object.keys(types.filter)" :key="key">{{ types.filter[key] }}</option>
        </select>
      </div>
      <div class="modal__field" v-if="event_date.filter.type === types.filter.date">
        <label for="eventDate_filterValue_date" class="modal__field__label">Datum</label>
        <input type="text" class="modal__field__input" id="eventDate_filterValue_date" v-model="event_date.filter.value">
      </div>
      <div class="modal__field" v-else>
        <label for="eventDate_filterValue_weekday" class="modal__field__label">Wochentag</label>
        <input type="number" min="0" max="6" class="modal__field__input" id="eventDate_filterValue_weekday" v-model="event_date.filter.value">
      </div>
      <button class="modal__action" @click="saveEventDate()">Speichern</button>
      <button class="modal__action" @click="event_date = false">Abbrechen</button>
    </div>
    <div class="modal modal--product" v-if="coupon_code">
      <strong class="modal__title">Coupon Code</strong>
      <div class="modal__field">
        <label for="product_name" class="modal__field__label">Code</label>
        <input type="text" class="modal__field__input" id="coupoCode_code" v-model="coupon_code.code" maxlength="6" minlength="6">
      </div>
      <div class="modal__field">
        <label for="product_price" class="modal__field__label">Wert</label>
        <input type="number" class="modal__field__input" id="couponCode_value" v-model="coupon_code.value">
      </div>
      <button class="modal__action" @click="saveCouponCode()">Speichern</button>
      <button class="modal__action" @click="coupon_code = false">Abbrechen</button>
    </div>
  </div>
</template>

<script>
import {version} from '../../package'
import TitleComponent from "@/components/title";
import {DATE_TYPES, FILTER_TYPES} from "@/util/eventdates-parser";
export default {
  name: "AdminView",
  components: {TitleComponent},
  data() {
    return {
      appVersion: version,
      credits: this.$store.state.credits,
      logType: '',
      logTypes: {
        transactions: {
          name: 'Transaktionen',
          default: []
        },
        purchases: {
          name: 'Käufe',
          default: {}
        },
        last_collection: {
          name: 'Credits eingesammelt',
          default: null
        },
      },
      product_buy_timeout: this.$store.state.config.product_buy_timeout,
      credits_per_day: this.$store.state.config.credits_per_day,
      credit_multiplier: this.$store.state.config.credit_multiplier,

      product: false,
      event_date: false,
      coupon_code: false,

      hasSaved: '',
    }
  },
  computed: {
    types: () => { return { date: DATE_TYPES, filter: FILTER_TYPES }},
  },
  methods: {
    duration(seconds) {
      let mins = Math.floor(seconds / 60);
      let secs = seconds % 60;
      return `${mins}m${secs > 0 ? ` ${secs}s`: ''}`
    },
    saveCredits() {
      this.$store.commit('setCredits', this.credits);
      this.saved('credits');
    },
    clearLogs() {
      const logType = this.logType;
      const defaultValue = this.logTypes[logType].default;

      this.$store.commit('clearLogs', { logType, default: defaultValue });
      this.saved('logs');
    },

    saveProductBuyTimeout() {
      this.$store.commit('setProductBuyTimeout', this.product_buy_timeout);
      this.saved('product_buy_timeout');
    },
    saveCreditsPerDay() {
      this.$store.commit('setCreditsPerDay', this.credits_per_day);
      this.saved('credits_per_day');
    },
    saveCreditsMultiplier() {
      this.$store.commit('setCreditsMultiplier', this.credit_multiplier);
      this.saved('credit_multiplier');
    },

    editProduct(productId = false) {
      if (productId === false) {
        this.product = { id: 'new', name: '', duration_seconds: 0, price: 0 };
      } else {
        const product = this.$store.state.config.products.find(product => product.id === productId);
        this.product = JSON.parse(JSON.stringify(product));
      }
    },
    saveProduct() {
      this.product.duration_seconds = parseInt(this.product.duration_seconds);
      this.product.price = parseInt(this.product.price);

      if (this.product.id === 'new') {
        const latestId = this.$store.state.config.products.reduce((largest, current) => (largest < current.id ? current.id : largest), 0);
        this.product.id = latestId + 1
        this.$store.commit('addProduct', this.product)
      } else {
        const index = this.$store.state.config.products.findIndex(product => product.id === this.product.id);
        this.$store.commit('updateProduct', { index, product: this.product })
      }
      this.product = false;
    },
    deleteProduct(productId) {
      const msg = `Produkt #${productId} löschen?`;
      const confirm = window.confirm(msg);
      if (confirm) {
        const index = this.$store.state.config.products.findIndex(product => product.id === productId);
        this.$store.commit('deleteProduct', index)
      }
    },

    editEventDate(eventDateId = false) {
      if (eventDateId === false) {
        this.event_date = { id: 'new', type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: '' }};
      } else {
        const event_date = this.$store.state.config.event_dates.find(event_date => event_date.id === eventDateId);
        this.event_date = JSON.parse(JSON.stringify(event_date));
      }
    },
    saveEventDate() {
      if (this.event_date.filter.type === FILTER_TYPES.weekday) this.event_date.filter.value = parseInt(this.product.filter.value);

      if (this.event_date.id === 'new') {
        const latestId = this.$store.state.config.event_dates.reduce((largest, current) => (largest < current.id ? current.id : largest), 0);
        this.event_date.id = latestId + 1
        this.$store.commit('addEventDate', this.event_date)
      } else {
        const index = this.$store.state.config.event_dates.findIndex(event_date => event_date.id === this.event_date.id);
        this.$store.commit('updateEventDate', { index, event_date: this.event_date })
      }
      this.event_date = false;
    },
    deleteEventDate(eventDateId) {
      const msg = `Event Date #${eventDateId} löschen?`;
      const confirm = window.confirm(msg);
      if (confirm) {
        const index = this.$store.state.config.event_dates.findIndex(event_date => event_date.id === eventDateId);
        this.$store.commit('deleteEventDate', index)
      }
    },
    addCouponCode() {
      this.coupon_code = { code: '', value: 0 };
    },
    saveCouponCode() {
      this.coupon_code.code = this.coupon_code.code.toUpperCase();
      this.coupon_code.value = parseInt(this.coupon_code.value);

      this.$store.commit('addCouponCode', this.coupon_code)
      this.coupon_code = false;
    },
    deleteCouponCode(code) {
      const msg = `Coupon Code #${code} löschen?`;
      const confirm = window.confirm(msg);
      if (confirm) {
        const index = this.$store.state.config.coupon_codes.findIndex(coupon_code => coupon_code.code === code);
        this.$store.commit('deleteCouponCode', index)
      }
    },

    saved(field) {
      this.hasSaved = field;
      setTimeout(() => { this.hasSaved = '' }, 2000)
    }
  },
};
</script>

<style lang="scss">
.admin {
  position: relative;

  &__setting {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
      margin-top: .5rem;
    }

    &__input,
    &__select {
      width: 50%;
      margin-left: auto;
    }
  }

  &__settings-group {
    margin: 2rem 0;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px 0;

      & + & {
        border-top: 1px solid silver;
      }

      &__label {
        margin-right: auto;
      }

      &__action {
        &--edit {

        }
        &--delete {
          color: red;
        }
      }
    }

    &__add-action {
      margin-top: .5rem;
      position: absolute;
      right: 0;
      color: green;
    }
  }

  .modal {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    background-color: white;
    box-shadow: 0 0 0 100vh rgba(black, .5);

    padding: 30px;

    &__field {
      padding: 10px 0;
    }
  }
}
</style>
