<template>
  <div id="app">
    <Header :can-close="canCloseCurrentRoute" />
    <main><router-view /></main>
    <div class="update-notice" v-if="updateExists">
      <div class="update-notice__box">
        <h2 class="update-notice__box__title">Ein neues Update ist verfügar!</h2>
        <button-component color="green" @click.native="refreshApp" class="update-notice__box__btn">Jetzt aktualisieren</button-component>
      </div>
    </div>
    <Navbar :active="currentNavItem" v-show="showBottomNavbar" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/header.vue";
import Navbar from "@/components/navbar.vue";
import update from '@/mixins/update'
import ButtonComponent from "@/components/button";

export default {
  components: {
    ButtonComponent,
    Header,
    Navbar,
  },
  mixins: [update],
  computed: {
    currentNavItem() {
      return this.$store.state.route.path.split("/")[1];
    },
    canCloseCurrentRoute() {
      return this.currentNavItem === "admin";
    },
    showBottomNavbar() {
      return !(this.currentNavItem === "admin" || this.currentNavItem === "timer" || this.currentNavItem === "install");
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;

  min-height: 100vh;
  max-width: 600px;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  main {
    margin-bottom: auto;
    padding: $spacing-xlarge $spacing-normal;
    max-height: 80vh;
    overflow-y: auto;
  }

  .update-notice {
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: rgba(black, .33);

    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__box {
      margin-top: 25vh;
      width: 90vw;
      padding: 2rem;
      background-color: white;

      &__title {
        text-align: center;
        margin: 0 0 1rem;
      }
    }
  }
}
</style>
