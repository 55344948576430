export default function(eventDates, date) {
    let match = false;

    eventDates.forEach(eventDate => {
        if (match) return;
        switch (eventDate.filter.type) {
            case FILTER_TYPES.weekday:
                match = date.getDay() === parseInt(eventDate.filter.value);
                break;
            case FILTER_TYPES.date:
                const filter = eventDate.filter.value.split('-');
                match = (date.getDate() === parseInt(filter[0]) && date.getMonth() === parseInt(filter[1]))
                break;
            default:
        }
    });

    return match;
}

export const FILTER_TYPES = {
    weekday: 'WEEKDAY',
    date: 'DATE',
};

export const DATE_TYPES = {
    closed: 'CLOSED',
    special: 'SPECIAL',
};
