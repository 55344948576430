<template>
  <div class="install">
    <title-component>App installieren</title-component>
    <strong>Share -> Add to homescreen</strong>
  </div>
</template>

<script>
import TitleComponent from "@/components/title";
export default {
  name: "InstallView",
  components: {TitleComponent},
};
</script>
