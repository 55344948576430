<template>
  <div class="arrow-icon" :class="'arrow-icon--' + direction">
    <img :src="require('@/assets/arrow.svg')" alt="Arrow Icon" class="arrow-icon__image">
  </div>
</template>

<script>

export default {
  name: "ArrowIcon",
  props: {
    direction: {
      type: String,
      default: 'right'
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.arrow-icon {
  display: inline-block;
  height: 1em;
  width: 1em;

  &--right {
    //transform: rotate(0deg);
  }
  &--down {
    transform: rotate(90deg);
  }
  &--left {
    transform: rotate(180deg);
  }
  &--up {
    transform: rotate(270deg);
  }

  &__image {
    height: 100%;
    width: 100%;
  }
}
</style>
