<template>
  <div class="points-counter">
    <strong class="points-counter__number">{{ $store.state.credits }}</strong>
    <lion-coin class="points-counter__icon" />
  </div>
</template>

<script>
import LionCoin from "@/components/lion-coin";
export default {
  name: "PointsCounter",
  components: {LionCoin},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.points-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-large;

  font-size: $font-xlarge;

  &__number {
    color: $text-primary;
    font-weight: bold;
    line-height: 1;
  }
  &__icon {
    margin-left: .25em;
  }
}
</style>
