import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { sync } from "vuex-router-sync";
import {unixToDateString} from "@/util/date-helper";

sync(store, router);

Vue.config.productionTip = false;

Vue.filter('readableCountdown', function (seconds) {
  if (!seconds || seconds < 0) return ''
  let countdown = [];

  const hours = Math.floor(seconds / 3600);
  if (hours > 0) countdown.push(`${hours} Stunden`);

  const minutes = Math.floor((seconds - (hours * 3600)) / 60);
  if (minutes > 0) countdown.push(`${minutes} Minuten`);

  if (hours === 0) countdown.push(`${Math.round(seconds % 60)} Sekunden`);

  return countdown.join(' ');
})

Vue.filter('unixToDateString', function (unix) {
  if (!unix) return '';

  return unixToDateString(unix);
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
