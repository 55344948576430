import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import {dateString, now, unix} from "@/util/date-helper";
import {DATE_TYPES, FILTER_TYPES} from "@/util/eventdates-parser";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    credits: 10,
    logs: {
      transactions: [
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
        { date: unix(), title: '', value: 0 },
      ],
      purchases: {},
      last_collection: null,
    },
    config: {
      product_buy_timeout: 12 * 3600,
      credits_per_day: 4,
      credit_multiplier: 2,
      products: [
        { id: 0, name: 'Kraulen', duration_seconds: 8 * 60, price: /*8*/ 12 },
        { id: 1, name: 'Massage', duration_seconds: 5 * 60, price: /*10*/ 15 },
        { id: 2, name: 'Kitzeln', duration_seconds: 1.5 * 60, price: /*6*/ 9 },
        { id: 3, name: 'Kitzeln', duration_seconds: 3 * 60, price: /*14*/ 21 },
        { id: 5, name: 'Kraulen Tagespass', duration_seconds: 10, price: /*14*/ 325 },
        { id: 4, name: 'Reverse Kraulen', duration_seconds: 8 * 60, price: /*-4*/ -6 },
      ],
      event_dates: [
        { id: 1, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.weekday, value: 1 }},
        { id: 2, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "1-1" }},
        { id: 3, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "8-8" }},
        { id: 4, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "16-8" }},
        { id: 5, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "17-10" }},
        { id: 6, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "24-12" }},
        { id: 7, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "25-12" }},
        { id: 8, type: DATE_TYPES.special, filter: { type: FILTER_TYPES.date, value: "26-12" }},
        { id: 9, type: DATE_TYPES.closed, filter: { type: FILTER_TYPES.weekday, value: 1 }},
        { id: 10, type: DATE_TYPES.closed, filter: { type: FILTER_TYPES.date, value: "16-7" }},
        { id: 11, type: DATE_TYPES.closed, filter: { type: FILTER_TYPES.date, value: "24-12" }},
        { id: 12, type: DATE_TYPES.closed, filter: { type: FILTER_TYPES.date, value: "1-1" }},
      ],
      coupon_codes: [
        { code: 'SUNNYB', value: 300 },
      ],
    }
  },
  getters: {
    closedDates: (state) => {
      return state.config.event_dates.filter(entry => entry.type === DATE_TYPES.closed)
    },
    specialDates: (state) => {
      return state.config.event_dates.filter(entry => entry.type === DATE_TYPES.special)
    },
    productAvailableInSeconds: (state) => (productId, timeout) => {
      const key = 'p' + productId;
      if(!Object.keys(state.logs.purchases).includes(key)) return -1;

      const availableAt = state.logs.purchases[key] + timeout;
      const availableIn = availableAt - unix();

      return availableIn < 0 ? -1 : availableIn;
    },
    productAvailableAtUnix: (state) => (productId, timeout) => {
      const key = 'p' + productId;
      if(!Object.keys(state.logs.purchases).includes(key)) return -1;

      return state.logs.purchases[key] + timeout;
    },
    isValidCouponCode: (state) => (code) => {
      const matchedCodes = state.config.coupon_codes.filter(entry => entry.code === code);
      if (matchedCodes.length !== 1) return false;
      return matchedCodes[0];
    }
  },
  mutations: {
    setCredits (state, n) {
      state.credits = parseInt(n)
    },
    setProductBuyTimeout (state, n) {
      state.config.product_buy_timeout = parseInt(n)
    },
    setCreditsPerDay (state, n) {
      state.config.credits_per_day = parseInt(n)
    },
    setCreditsMultiplier (state, n) {
      state.config.credit_multiplier = parseInt(n)
    },
    clearLogs (state, payload) {
      state.logs[payload.logType] = payload.default
    },

    addProduct (state, product) {
      state.config.products.push(product);
    },
    updateProduct (state, payload) {
      state.config.products[payload.index] = payload.product;
    },
    deleteProduct (state, index) {
      state.config.products.splice(index, 1);
    },
    addEventDate (state, event_date) {
      state.config.event_dates.push(event_date);
    },
    updateEventDate (state, payload) {
      state.config.event_dates[payload.index] = payload.event_date;
    },
    deleteEventDate (state, index) {
      state.config.event_dates.splice(index, 1);
    },
    addCouponCode (state, coupon_code) {
      state.config.coupon_codes.push(coupon_code);
    },
    deleteCouponCode (state, index) {
      state.config.coupon_codes.splice(index, 1);
    },

    collectCredits (state, n) {
      // mutate state
      state.credits += n
      state.logs.last_collection = unix();
      // log transaction
      state.logs.transactions.unshift({ date: unix(), title: 'LionCoins gesammelt', value: n })
      state.logs.transactions.pop()
    },

    buyProduct (state, product) {
      // mutate credits
      state.credits -= product.price
      if (state.credits < 0) state.credits = 0
      // log transaction
      state.logs.transactions.unshift({ date: unix(), title: product.name, value: product.price * -1 })
      state.logs.transactions.pop()
      // add purchase
      state.logs.purchases['p' + product.id] = unix();
    },
    refundProduct (state, product) {
      // mutate credits
      state.credits += product.price
      if (state.credits < 0) state.credits = 0
      // log transaction
      state.logs.transactions.unshift({ date: unix(), title: 'STORNIERT ' + product.name, value: product.price })
      state.logs.transactions.pop()
      // add purchase
      state.logs.purchases['p' + product.id] = 0;
    },

    redeemCode (state, payload) {
      // mutate state
      state.credits += payload.value
      // delete code
      state.config.coupon_codes.splice(payload.index, 1);
      // log transaction
      state.logs.transactions.unshift({ date: unix(), title: 'Coupon eingelöst', value: payload.value })
      state.logs.transactions.pop()
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
