<template>
  <div class="lion-coin">
    <img :src="require('@/assets/lion_coin.png')" alt="LoinCoin" class="lion-coin__image">
  </div>
</template>

<script>

export default {
  name: "LionCoin",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lion-coin {
  display: inline-block;
  height: 1em;
  width: 1em;

  &__image {
    height: 100%;
    width: 100%;
  }
}
</style>
