<template>
  <div class="timer">
    <title-component>Timer: {{ product.name }}</title-component>

    <svg class="timer__gauge" viewBox="0 0 50 50" :class="timer ? '' : 'timer__gauge--timer-paused'">
      <path class="timer__gauge__background" d="M15,40 a20,20 0 1 1 25,0" />
      <path class="timer__gauge__indicator" :class="countdown >= 0 ? 'timer__gauge__indicator--countdown' : ''" stroke-dasharray="100" :stroke-dashoffset="progress" d="M15,40 a20,20 0 1 1 25,0" />
      <text class="timer__gauge__text" x="55%" y="50%">{{ countdown >= 0 ? (countdown === 3 ? 'X' : countdown + 1) : remaining }}</text>
    </svg>

    <button-component color="red" @click.native="stopTimer()" v-if="timer">
      <strong>Pause</strong>
    </button-component>
    <template v-else>
      <button-component color="green" @click.native="startTimer()" v-if="seconds > 0">
        <strong v-if="seconds === duration">Timer starten</strong>
        <strong v-else>Weiter</strong>
      </button-component>
      <button-component @click.native="closePage()" v-else>
        <strong>Schließen</strong>
      </button-component>

      <button-component color="red" @click.native="cancelPurchase" v-if="seconds === duration">
        <strong>Abbrechen</strong>
      </button-component>
    </template>



  </div>
</template>

<script>
import TitleComponent from "@/components/title";
import ButtonComponent from "@/components/button";
import NoSleep from 'nosleep.js';

export default {
  name: "TimerView",
  props: {
    productId: Number,
  },
  data() {
    return {
      audio: {
        //beep: new Audio(require('@/assets/beep.mp3')),
        ding: new Audio(require('@/assets/ding.mp3')),
        bell: new Audio(require('@/assets/bell.mp3')),
      },
      timer: null,
      seconds: 0,
      countdown: -1,
      countdownMax: 3,
      noSleep: null,
    }
  },
  components: {ButtonComponent, TitleComponent},
  computed: {
    duration() {
      return this.product.duration_seconds;
    },
    product() {
      return this.$store.state.config.products.find(product => product.id === this.productId);
    },
    remaining() {
      let mins = Math.floor(this.seconds / 60);
      let secs = this.seconds % 60;
      return `${mins}:${secs < 10 ? '0' : ''}${secs}`
    },
    progress() {
      if (this.countdown === -1 && this.seconds === this.duration) return 100;

      const timerProgress = 1 - (this.seconds / this.duration);
      let progress = timerProgress;

      if (!this.timer) { return 100 }

      if (this.countdown > 0) {
        const max = timerProgress;

        const countdownProgress = 1 - (this.countdown / this.countdownMax);
        progress = 1 - ((1 - max) * countdownProgress);
      }

      return Math.round(100 * progress);
    }
  },
  methods: {
    cancelPurchase() {
      this.$store.commit('refundProduct', this.product);
      this.closePage();
    },
    startTimer() {
      this.countdown = this.countdownMax - 1;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.countdown--;
          this.audio.ding.play();
          this.stopTimer(false);

          this.noSleep.enable();
          this.seconds--;
          this.timer = setInterval(() => {
            if (this.seconds === this.duration/2) {
              this.audio.ding.play();
            }

            if (this.seconds > 0) {
              this.seconds--;
            } else {
              this.audio.bell.play();
              this.stopTimer();
            }
          }, 1000);
        }
      }, 1000);
    },
    stopTimer(pause = true) {
      this.noSleep.disable();
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      //if (pause) this.countdown = this.countdownMax;
    },
    closePage() {
      this.noSleep.disable();
      this.$router.push('/shop');
    },
  },
  mounted() {
    this.seconds = this.duration;

    this.noSleep = new NoSleep();

    Object.keys(this.audio).forEach(function(key) {
      this.audio[key].volume = 1.0;
    });
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style lang="scss">
.timer {
  &__gauge {
    $size: 80vw;
    height: $size;
    width: $size;
    margin: 0 auto;

    &__background,
    &__indicator {
      fill: none;
      stroke-width: 5px;
      stroke-linecap: round;
    }

    &__background {
      stroke: silver;
    }
    &__indicator {
      stroke: $green;

      &--countdown {
        stroke: $red;
      }

      transition: stroke-dashoffset 1s linear;
    }

    &--timer-paused &__indicator {
      transition: none !important;
    }

    &__text {
      text-anchor: middle;
      dominant-baseline: middle;
      font: bold 12px sans-serif;
      fill: $text-primary;
    }
  }
}
</style>
