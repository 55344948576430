<template>
  <nav class="navbar">
    <router-link
      to="/points"
      class="navbar__item"
      :class="{ 'navbar__item--active': active === 'points' }"
    >
      <span class="navbar__item__icon"></span>
      <span class="navbar__item__text">Konto</span>
    </router-link>
    <router-link
      to="/shop"
      class="navbar__item"
      :class="{ 'navbar__item--active': active === 'shop' }"
    >
      <span class="navbar__item__icon"></span>
      <span class="navbar__item__text">Einlösen</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: {
    active: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  width: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;

  background-color: $background-primary;
  box-shadow: 0 0 $spacing-normal 0 $shadow-primary-color;

  &__item {
    width: 50%;
    padding: $spacing-large 0;
    border-bottom: $spacing-small solid transparent;

    text-align: center;
    text-decoration: none;

    &--active {
      background-color: $background-primary--active;
      border-bottom-color: $border-primary-color;
    }

    &__icon {}
    &__text {
      font-size: $font-large;
      color: $text-primary;
      font-weight: bold;
    }
  }
}
</style>
