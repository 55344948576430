<template>
  <div class="coupons">
    <title-component has-rich-content>
      <router-link to="/points"><ArrowIcon direction="left" /></router-link>
      <span>Coupon einlösen</span>
      <span><!--Placeholder for Flex Alignment -->&nbsp;</span>
    </title-component>
    <div class="coupons__form">

      <div class="coupons__form__fields">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[0]" ref="code0" @input="event => onCodeInput(0, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[1]" ref="code1" @input="event => onCodeInput(1, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[2]" ref="code2" @input="event => onCodeInput(2, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[3]" ref="code3" @input="event => onCodeInput(3, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[4]" ref="code4" @input="event => onCodeInput(4, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
        <input type="text" maxlength="1" class="coupons__form__fields__input" v-model="code[5]" ref="code5" @input="event => onCodeInput(5, event.target.value)" @focus="event => setCursorToEndOfInput(this)">
      </div>

      <button-component
        class="coupons__form__button"
        @click.native="claimCouponCode"
        :disable="!isValidCode"
        disabled-message=""
      >
        <strong class="coupons__form__button__title">
          Jetzt einlösen
        </strong>
        <template v-if="isValidCode">
          <span class="coupons__form__button__value">
            +{{ codeValue }}
          </span>
          <lion-coin class="coupons__form__button__icon" />
        </template>
        <template v-else>
          <span class="coupons__form__button__value"></span>
        </template>
      </button-component>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/title";
import LionCoin from "@/components/lion-coin";
import ButtonComponent from "@/components/button";
import ArrowIcon from "@/components/icon-arrow";

export default {
  name: "CouponsView",
  components: {ArrowIcon, ButtonComponent, LionCoin, TitleComponent},
  data() {
    return {
      code: ['', '', '', '', '', ''],
    }
  },
  computed: {
    fullCode() {
      //return this.code.first + this.code.second + this.code.third + this.code.fourth + this.code.fifth + this.code.sixth;
      return this.code.join('');
    },
    isValidCode() {
      if (this.fullCode.length !== 6) return false;
      return this.$store.getters.isValidCouponCode(this.fullCode);
    },
    codeValue() {
      return this.isValidCode && this.isValidCode.value;
    }
  },
  methods: {
    onCodeInput(position, value) {
      if (value === '' || !value.match(/^[a-zA-Z]$/)) value = '';

      if (value.match(/^[a-z]$/)) value = value.toUpperCase();
      this.code[position] = value;

      if (value.match(/^[A-Z]$/) && position <= 4) this.$refs[`code${position + 1}`].focus();
    },
    setCursorToEndOfInput(elem, length = 1) {
      //elem.setSelectionRange(length, length);
    },
    claimCouponCode() {
      if (!this.isValidCode) return;
      const index = this.$store.state.config.coupon_codes.findIndex(entry => entry.code === this.isValidCode.code);
      this.$store.commit('redeemCode', { value: this.isValidCode.value, index });

      this.code = ['','','','','',''];

      this.$router.push('/points');
    }
  },

};
</script>

<style lang="scss">
.coupons {
  &__form {
    &__fields {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 1rem 0;

      &__input {
        height: calc(75vw / 6) * 1.5;
        width: calc(75vw / 6);
        font-size: calc(75vw / 6) * .5;
        text-align: center;
        font-family: inherit;
        font-weight: bold;

        &:focus {
          outline-color: $primary;
        }
      }
    }
    &__button {
      &__title {}
      &__value {
        margin-left: auto;
        font-weight: bold;
        margin-right: .5em;
        color: $green;
      }
      &__icon {}
    }
  }
}
</style>
